:root {
  --paper-max-width: 1280px;
  --paper-width: 1024px;
  --paper-min-width: 320px;
  --background-color: #fffad9;
  --paper-background-color: #ffffff;
  --accent-color: #ffdd00;
  --accent-gradient: linear-gradient(90deg, var(--paper-background-color) 0%, var(--accent-color) 50%, var(--paper-background-color) 100%);
  ;
  --accent-color-dark: #cdb200;
}

.App {
  position: relative;
  display: flex;
  justify-content: center;
  background-color: var(--background-color);
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: #282c34;
}

.App-link {
  color: var(--accent-color-dark);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App-paper {
  max-width: var(--paper-max-width);
  /* width: var(--paper-width); */
  min-width: var(--paper-min-width);
  @media (max-width: 992px) {
    padding-inline: 0;
  }
}

.border-gradient {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 1px;
  border-image-source: var(--accent-gradient);
}

.separator {
  width: 60%;
  background-color: lightgoldenrodyellow;
  height: 2px;
  border-radius: 10px;
  margin-left: 20%;
  margin-block: 0.5rem;
}

.mantine-Navbar-root {
  @media (max-width: 992px) {
    width: 100%;
    z-index: 110;
    position: fixed;
    top: 0;
    padding-top: 70px;
    left: 0;
    box-shadow: 0 0 50px grey;
    height: fit-content;
  }

  transition: all .5s ease-in-out;
}

.mantine-Navbar-hidden {
  @media (max-width: 992px) {
    display: flex;
    height: 0;
    padding-block: 0;
  }
}